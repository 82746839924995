import React, { useState, useRef, useEffect } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Row, Col, Form, Button } from 'react-bootstrap'
import axios from 'axios'

function Book() {
  let location = useLocation()
  let { path, url } = useRouteMatch()
  const [mail, setMail] = useState('')
  const isTransmitted = useRef(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleMailChange = (e) => setMail(e.target.value)
  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      .post('/api/update/', {
        email: mail,
        tags: ['insider-survey-book'],
      })
      .then((response) => {
        isTransmitted.current = true
        setShowSuccess(true)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (isTransmitted.current) setShowSuccess(true)
  }, [isTransmitted])

  return (
    <Switch location={location}>
      <Route key="home" exact path={path}>
        <Redirect to={`${url}/survey`} />
      </Route>

      <Route key="survey" path={`${path}/survey`}>
        <Row>
          <Col className="mx-0 px-1">
            <iframe
              title="formBook"
              src="https://docs.google.com/forms/d/e/1FAIpQLScyFYyZF3blTrhqsZ6mlOfyrX7mMRnpX97A1s2yNAQVGgo5eA/viewform?embedded=true"
              width={100 + '%'}
              height={window.innerHeight - 120}
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            >
              Loading…
            </iframe>
          </Col>
        </Row>
      </Route>
      <Route key="finish" path={`${path}/finish`}>
        <Row>
          {!showSuccess ? (
            <Col>
              <h5>Danke für Deine Unterstützung!</h5>
              <p>
                Bitte trage in folgendes Feld noch Deine E-Mail-Adresse ein,
                damit wir Dir nach Veröffentlichung des Buchs einen Rabattcode
                dafür zusenden können:
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={mail}
                    onChange={handleMailChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Absenden
                </Button>
              </Form>
            </Col>
          ) : (
            <Col>
              <p>Alles erledigt!</p>
            </Col>
          )}
        </Row>
      </Route>
    </Switch>
  )
}

export default Book
