import React from 'react'
import {
  Switch,
  Route,
  Link,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Row, Col, Image, Button } from 'react-bootstrap'
import Book from './book.js'

function Surveys() {
  let location = useLocation()
  let { path, url } = useRouteMatch()

  return (
    <section>
      <Switch location={location}>
        <Route key="home" exact path={path}>
          <Row>
            <Col className="mx-0 px-0">
              <h3>Umfragen</h3>
              <p>
                Die Wunderland Insider Umfragen sind nicht öffentlich.
                Mitglieder erhalten den Zugang per E-Mail.
              </p>
              <p>
                Möchten Sie Wunderland Insider werden?{' '}
                <a href="/">Mehr Infos und Registrierung</a>
              </p>
            </Col>
          </Row>
        </Route>

        <Route key="book" path={`${path}/book`}>
          <Book />
        </Route>
      </Switch>
    </section>
  )
}

export default Surveys
